.navBar .col-md-3 {
    z-index: 999;
  }

  ul.main-menu {
    display: inline-flex;
    list-style: none;
    margin: 0px auto 0;
    padding: 0;
    float: right;
    z-index: 999;
  }

  ul.main-menu>li.menu-item:first-child {
    padding-left: 0;
    margin-left: 0;
  }
  .menu-item {
    line-height: 23px;
  }
  li {
    list-style-type: none;
  }
  .menu-item a {
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    position: relative;
    display: block;
    color: #545050;
    margin: 17px 17px;
    text-decoration: none;
    cursor: pointer;
  }
  .header__middle {
    display: flex;
    width: 100%;
    float: left;
    /*position: -webkit-sticky;   
    position: sticky;*/
    position: fixed;
    top: 0;
    z-index: 999;
    background: content-box radial-gradient(#FBFCF6, #D3D6D8);
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.5);
    margin-bottom: 10px;
  }
  .header__middle__logo {
    width: 15%;
    /* display: inline-block; */
    float: left;
    margin: -8px 0px 3px 16px;
    z-index: 999;
  }
  .header__middle__button {
    width: 10%;
    /* display: inline-block; */
    float: right;
    padding: 20px 25px 0px 95px;
    z-index: 999;
    /* border-left: 1px solid lavender; */
  }
  .header__middle__menus {
    width: 66%;
    /* display: inline-block; */
    float: right;
  }
  .menu-item.menu__qqqitem a{
    margin: 27px 10px;
  }
  .header__middle__logo > a > h1 {
    color: #07a8ff;
    text-transform: uppercase;
    text-decoration: none;
    margin-top: 5px;
  }
  /* .active{
    color: #ff1800!important;
  } */
  .header__middle__menus span.text {
    position: absolute;
    right: 177px;
    bottom: 10px;
    font-size: 14px;
    color: #ff1800;
  }
  .sub__menus__arrows{
    position: relative;
  }
  .sub__menus__arrows svg, .sub__menus__arrows__full svg{
    padding-top: 0px;
    position: absolute;
    top: 13px;
  }
  .sub__menus {
    position: absolute;
    display: none;
    background: white;
    border: 1px solid lavender;
    width: 300px;
    left: -17px;
    padding: 2px 0 0 0;
    z-index: 999;
    box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
  }
  
  @media (max-width:567px) { 
    .header__middle .active {color: #ff3d00!important;}
  }
  
  @media (min-width:792px) { 
    .sub__menus__arrows:hover .sub__menus{
      display: block!important;
    }
    .sub__menus__arrows__full:hover .sub__menus{
      display: block;
      z-index: 99900;
    }
  }
  .menu-item .sub__menus  a {
    padding: 7px 0 0px 0;
    margin: 7px 27px;
  }
  .menu-item:hover > a{
    color: #07a8ff !important;
  }
  .menu-item .sub__menus li:hover a{
    color: #07a8ff !important;
  }
  
  .header__middle__logo img{
    max-width: 207px;
    margin: 17px 0 0 0;
  }
  
  
  @media(max-width:880px) {
  .header__middle__logo {width: 28%; margin: -10px 0 0 5px;}
  .header__middle__button {width: 62%; margin: -2px 35px 0 -10px;}
  .header__middle__menus {width: 5%; margin: 4px 0 0 -5px;}
  .header__middle__logo img {max-width: 197px;}
  .main-nav .menubar__button:hover {background-color: orangered;}
  .main-nav .menu-item:hover > a {color: #07a8ff !important}
  .main-nav .menubar__button {
    display: block!important;
    float: right;
    background-color: #080808;
    color: #fff;
    padding: 6px 7px;
    border-radius: 5px;
    margin: 10px;
    cursor: pointer;
    position: relative;
    z-index: 10037;
  }
  .main-nav .menubar__button svg{font-size: 27px;}
  .main-nav ul.menuq2 {
    display: block!important;
    position: absolute;
    left: 0;
    z-index: 10007;
    background-color: #d8f1ff;
    border-bottom: 3px solid orangered;
    right: 0;
    padding-bottom: 17px;
    padding-top: 5px;
  }
  .main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
  .main-nav  ul.main-menu {display: none}
  .main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
  .sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
  .sub__menus__Active2{display: block!important;}
  .menus__categorysss{display: none;}
  .menus__categorysss2{display: none;}
  .menu-item .sub__menus__full a {text-align: left;}
  
}
  

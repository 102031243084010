.react-share__ShareButton {
    margin-right: 1rem;

       & a svg {
          &:hover {
             rect {
                fill: var(--link-color) !important;
            }
        }
    }
}

@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');

.outer-container {
  /* Center child horizontally*/
  display: flex;
  justify-content: center;
}

.main-container {
  width: 98% !important;
  margin: 80px 20px 20px 10px;
  font-family: 'Roboto Slab', serif;
  font-size: 15px;
  /* background-color: whitesmoke; */
  /* border: 1mm ridge rgba(211, 220, 50, .6); */
}

.content {
  margin: 10px; 
}

.preview {
  max-width: 300px;
}

.dropdown-toggle {
  margin-top: 3px;
  margin-bottom: 6px;
  min-width: 300px;
  background-color: white;
  color: darkslategrey;
}

.style-image-dropdown {
  max-height: 350px;
  overflow-y: scroll;
  min-width: 300px;
}

.highlight {
  color: orangered;
  font-size: 20px;
}

.highlight1 {
  color: rgb(0, 0, 128); 
  background-color: rgb(255,235,205);  
}

.headline {
  background-image: url('images/banner.jpg');
  width: 100%;
  height: 200px;
}

.title {
  font-size: 25px;
  text-align: center;
  /* background-color: rgb(172, 190, 190); */
}

.introduction {
  font-size: 17px;
  text-align: left;
  padding: 1%;
}

.btn {
  border-radius: 4px;
  font-size: 13px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 30px;
  border: none;
  letter-spacing: .02rem;
  -webkit-box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.2);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  position: relative;
  top: 0;
}

.btn.highlight1 {
  color: rgb(0, 0, 128); 
  background-color: rgb(255,235,205);  
}

.color-green {
  color: green;
}

input[type=file] {
  max-width: 512px;
  width: 100%;
}

.text-wrap {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}

.card-img-top {
  max-width: 512px;
  width: 100%;
}

.card-title {
  max-width: 512px;
  width: 100%;
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}

.hide {
  display: none;
}

.row.hide {
  display: none;
}

.col.hide {
  display: none;
}

.termDIV:focus + .hide {
  max-width: 512px;
  display: block;
}

.termSelector:focus + .hide {
  max-width: 512px;
  display: block;
  user-select: none; /* this sets the element unselectable, unlike texts */
  cursor: pointer; /* changes the client's cursor */ 
  touch-action: manipulation; /* disables tap zoom delaying for acting like real button */
  background: transparent; /* remove button style */
  border: 0; /* remove button style */
}
/*
.nav>li>a.active,
.nav>li>a:hover,
.nav>li>a:focus {
   background-color: #5c8a36;
   color: red;
}
*/

.ReactGridGallery_tile-description {
  text-align: center;
}
